import { FALLBACK_LOCALE_POLICY } from '@yescapa-dev/ysc-components/i18n'
import type { YescapaLocales } from '@yescapa-dev/ysc-components/i18n'

export const useGetLanguagesToFetch = () => {
  const { locale } = useI18n()

  return () => {
    const fallbacks = FALLBACK_LOCALE_POLICY[locale.value as YescapaLocales] ?? []

    return [locale.value, ...fallbacks, ...FALLBACK_LOCALE_POLICY.default]
  }
}
