import type { ParsedContent } from '@nuxt/content/types'

export const useFindOneLocalizedContentWithFallback = () => {
  const nuxtApp = useNuxtApp()

  const getLanguagesToFetch = useGetLanguagesToFetch()

  return async <T = ParsedContent>(fileName: string) => {
    const languagesToFetchFIFO = getLanguagesToFetch()

    let error
    while (languagesToFetchFIFO.length > 0) {
      const lang = languagesToFetchFIFO.shift() as string // won't ever be undefined

      try {
        return await nuxtApp.runWithContext(() => queryLocalizedContent<T>(fileName, lang).findOne())
      }
      catch (e) {
        if (import.meta.dev) {
          console.log(`Failed to fetch lang ${lang} for file ${fileName}, fallbacks left: ${JSON.stringify(languagesToFetchFIFO)}`)
        }
        error = e
      }
    }

    throw error
  }
}
