import type { ParsedContent } from '@nuxt/content/types'

export const queryLocalizedContent = <T = ParsedContent>(file: string, locale: string) => {
  if (locale === 'pt-pt') {
    // pt-pt content are under 'pt' in content folder
    locale = 'pt'
  }
  else if (locale === 'en-gb') {
    // en-gb content are under 'en' in content folder
    locale = 'en'
  }

  if (locale.includes('-')) {
    const [top, second] = locale.split('-')

    locale = `${top}-${second.toUpperCase()}`
  }

  return queryContent<T>(file).locale(locale)
}
